import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import * as S3 from 'aws-sdk/clients/s3';
import { environment, dev_userpool_keys } from '../../environments/environment';
import { Router } from '@angular/router';


export interface repos {

}


@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  imagePrefix = dev_userpool_keys.imagePrefix;
  encrypt_text=dev_userpool_keys.encrypt_text;
  serviceurl:string;
  url: string = 'http://localhost:3000/changetomorrow';
 headers= new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*')
  .set('Strict-Transport-Security', 'max-age=31536000; includeSubDomains; preload')
  .set('Content-Security-Policy','self')
  .set('X-Frame-Options', 'SAMEORIGIN')
  .set('X-Content-Type-Options', 'nosniff')
  .set('Referrer-Policy', 'no-referrer, strict-origin-when-cross-origin')
  .set('Permissions-Policy', 'self');
  

  constructor(public http: HttpClient, private router:Router) {
    // this.serviceurl = 'https://dev.changetomorrow.org/changetomorrow/';
    // if(window.location.hostname == "changetomorrow.org"){
    //   this.serviceurl = 'https://changetomorrow.org/changetomorrow/';
    // }
    this.serviceurl = environment.apiURL;
    localStorage.setItem("apiurl", this.serviceurl)
  }

  // load template
  getReposloadtemplate(queryparameterid: string, queryparameter1: string,queryparameter2: string, endpoint1: string, endpoint2: string) {
    let params = new HttpParams()
      .set('templateid', queryparameterid)
      .set('slugName', queryparameter1)
      .set('type', queryparameter2)

    return this.http.get(this.serviceurl + '/' + endpoint1 + '/' + endpoint2, { params, 'headers': this.headers });
  }
  // candidatebioandlogo
  getReposrecipientlogobio(queryparameterid: string, queryparameter1: string, endpoint1: string, endpoint2: string, responseType) {
    let params = new HttpParams()
      .set('userid', queryparameterid)
      .set('tempid', queryparameter1)
   
    return this.http.get(this.serviceurl + '/' + endpoint1 + '/' + endpoint2, { params, 'headers': this.headers });
  }

  GetUserEmailid() {
    let userid = localStorage.getItem("authenticationdata");
    if (userid === null || userid === '' || userid === undefined) {
    return null;
    }

    else {
    let data = JSON.parse(localStorage.getItem("authenticationdata"));
    return data.email;
    }
    }
	GetUsertype() {
      let usertype = localStorage.getItem("authenticationdata");
      if (usertype === null || usertype === '' || usertype === undefined) {
      return null;
      }
  
      else {
      let data = JSON.parse(localStorage.getItem("authenticationdata"));
      return data.profile;
      }
      }

    GetFuseid() {
      let data = JSON.parse(localStorage.getItem("authenticationdata"));
      if(data==null){
        this.router.navigate(["/login"]);
        return;
      }
      return data.isPendingApproved;
    }

  GetFullname() {
    let data = JSON.parse(localStorage.getItem("authenticationdata"));
    if(data==null){
      this.router.navigate(["/login"])
      return;
    }
    return data.name;
  }

  // get predefined templatelist
  getRepostemplateslist(endpoint1: string,) {
    let params = new HttpParams()
    return this.http.get(this.serviceurl + '/' + endpoint1, { params, 'headers': this.headers });
  }

  // get my contribution templatetist
  getReposmycontributiontemplateslist(queryparameterid: string, endpoint1: string, endpoint2: string) {
    let params = new HttpParams()
      .set('userid', queryparameterid)
    return this.http.get(this.serviceurl + '/' + endpoint1 + '/' + endpoint2, { params, 'headers': this.headers });
  }

  getRepos(queryparameterid: string, endpoint1: string, endpoint2: string, endpoint3: string, responseType) {
  
    const httpOptions = {
      responseType: responseType,
      params: { 'id': queryparameterid }
    };
    return this.http.get(this.url + '/' + endpoint1 + '/' + endpoint2 + '/' + endpoint3, httpOptions);
  }
  getslugname(queryparameterid: string, endpoint1: string) {
    let params = new HttpParams()
      .set('slugname', queryparameterid)
    return this.http.get(this.serviceurl + endpoint1, { params, 'headers': this.headers });
  }
  gettemplateid(queryparameterid: string, endpoint1: string) {
    let params = new HttpParams()
      .set('templateid', queryparameterid)
    return this.http.get(this.serviceurl + endpoint1, { params, 'headers': this.headers });
  }
  getReposGetControlList(endpoint1: string, endpoint2: string, endpoint3: string, endpoint4: string,) {
    
    return this.http.get(this.serviceurl + '/' + endpoint1 + '/' + endpoint2 + '/' + endpoint3 + '/' + endpoint4, {'headers': this.headers});
  }

  getReposDonationamount(endpoint1: string, endpoint2: string, endpoint3: string, endpoint4: string) {
   
    return this.http.get(this.serviceurl + endpoint1 + '/' + endpoint2 + '/' + endpoint3 + '/' + endpoint4, {'headers': this.headers});
  }

  getReposContributionRules(endpoint1: string, endpoint2: string, endpoint3: string, endpoint4: string) {
  
    return this.http.get(this.serviceurl + endpoint1 + '/' + endpoint2 + '/' + endpoint3 + '/' + endpoint4, {'headers': this.headers});
  }

  getdeletetemplatelist(queryparameterid: string, endpoint1: string, endpoint2: string,) {
    let params = new HttpParams()
      .set('templateId', queryparameterid)
    return this.http.get(this.serviceurl + endpoint1 + '/' + endpoint2, { params,'headers': this.headers });
  }

  getReposthankyounote(queryparameterid: string, endpoint1: string, endpoint2: string,) {
    let params = new HttpParams()
      .set('templateid', queryparameterid)
    return this.http.get(this.serviceurl + endpoint1 + '/' + endpoint2, { params, 'headers': this.headers });
  }

  getcontactinfobyid(endpoint1: string, endpoint2: string,queryparameterid: string, ) {
    let params = new HttpParams()
      .set('userid', queryparameterid)
    return this.http.get(this.serviceurl + endpoint1 + '/' + endpoint2, { params, 'headers': this.headers });
  }


  signUp(endpoint: string, endpointEnd: string, body: any, reqOpts?: any) {
    return this.http.post(this.serviceurl + '/' + endpoint + '/' + endpointEnd, body, {'headers': this.headers});
  }


  createslugform(endpoint: string, endpointEnd: string, endpointEnd2: string, body: any, reqOpts?: any) {
    return this.http.post(this.serviceurl + endpoint + '/' + endpointEnd + endpointEnd2, body, {'headers': this.headers});
  }
  PostPayment(endpoint: string, endpointEnd: string, body: any, reqOpts?: any) {
    return this.http.post(this.serviceurl + endpoint + '/' + endpointEnd, body, {'headers': this.headers});
  }
  savepublish(endpoint: string, endpointEnd: string, body: any, reqOpts?: any) {
    return this.http.post(this.serviceurl + endpoint + '/' + endpointEnd, body, {'headers': this.headers});
  }



  contactUs(endpoint: string, endpointEnd: string, body: any, reqOpts?: any) {
    return this.http.post(this.serviceurl + '/' + endpoint + '/' + endpointEnd, body, {'headers': this.headers});
  }

  get1(url) {
    return this.http.get(url);
  }

  login(endpoint: string, endpointEnd: string, body: any) {
    return this.http.post(this.serviceurl + endpoint + '/' + endpointEnd, body, { 'headers': this.headers , responseType: 'text' as 'json' });
  }

  createpassword(url:any, body: any, reqOpts?: any) {
    return this.http.post(this.serviceurl + url, body, {'headers': this.headers, responseType: 'text' as 'json' });
  }

  get(url, responsetype) {
    return this.http.get(url, responsetype);
  }
  
  post(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.url + '/' + endpoint, body, {'headers': this.headers});
  }

  put(url: string, endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(url + '/' + endpoint, body, {'headers': this.headers});
  }

  delete(url: string, endpoint: string, reqOpts?: any) {
    return this.http.delete(url + '/' + endpoint, {'headers': this.headers});
  }

  delete_donors(url:string, body){
    return this.http.post(this.serviceurl + '/' + url, body, {'headers': this.headers});
  }

  patch(url: string, endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(url + '/' + endpoint, body, {'headers': this.headers});
  }

  //upload image to s3 bucket
  uploadFile(file, key) {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: dev_userpool_keys.accessKeyId,
        secretAccessKey: dev_userpool_keys.secretAccessKey,
        region: dev_userpool_keys.region,
      }
    );
    const params = {
      Bucket: dev_userpool_keys.Bucket,
      Key: key,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };
    const promise = new Promise((resolve, reject) => {
      bucket.upload(params, function (err, data) {
        if (err) {
          console.log('There was an error uploading your file: ', err);
          resolve(false);
        }
        console.log('Successfully uploaded file.', data);
        resolve(true);
      });
    });
    return promise;
  }

  enroll(endpoint: string, endpointEnd: string, body: any) {
    console.log(this.serviceurl + '/' + endpoint + '/' + endpointEnd);
    return this.http.post(this.serviceurl + '/' + endpoint + '/' + endpointEnd, body, { 'headers': this.headers, responseType: 'text' as 'json' });
  }
  // password(body: any, reqOpts?: any) {
  //   return this.http.post(this.serviceurl + body, null, {'headers': this.headers, responseType: 'text' as 'json' });
  // }

  password_post(url:any, body:any){
    return this.http.post(this.serviceurl + url, body, {'headers': this.headers, responseType: 'text' as 'json' });
  }

  // reset(body: any, reqOpts?: any) {
  //   return this.http.post(this.serviceurl + body, null, {'headers': this.headers, responseType: 'text' as 'json' });
  // }

  reset_post(url:any, body:any){
    return this.http.post(this.serviceurl + url, body, {'headers': this.headers, responseType: 'text' as 'json' });
  }

  search_directory(body: any, reqOpts?: any) {
    return this.http.get(this.serviceurl + body, {'headers': this.headers} );
  }

   get_stateslist(body:any, reqOpts?: any){
    return this.http.get(this.serviceurl+body, {'headers': this.headers });
   }
   candidateBio(body:any, reqOpts?: any){
    return this.http.get(this.serviceurl+body, {'headers': this.headers });
   }

   donarreport(body:any, reqOpts?: any){
    return this.http.get(this.serviceurl+body, {'headers': this.headers });
   }
   graph_data(body:any, reqOpts?: any){
    return this.http.get(this.serviceurl+body, {'headers': this.headers });
   }
   myProfile_candidate(body:any, reqOpts?: any){
    return this.http.get(this.serviceurl+body, {'headers': this.headers });
   }

   save_candidateinfo(req: string, body: any) {
    return this.http.post(this.serviceurl + req, body, {'headers': this.headers, responseType: 'text' as 'json' });
    }

    organization_EIN(req: string) {
      console.log(this.serviceurl + req);
     return this.http.get(this.serviceurl + req, {'headers': this.headers, responseType: 'text' as 'json' });
     }
     
    myCandidate_candidate(body:any, reqOpts?: any){
      return this.http.get(this.serviceurl+body, {'headers': this.headers });
     }
     save_candidateinfo2(req: string, body: any) {
      return this.http.post(this.serviceurl + req, body, {'headers': this.headers, responseType: 'text' as 'json' });
      }

      myProfile_user(body:any, reqOpts?: any){
        console.log(this.serviceurl+body);
        return this.http.get(this.serviceurl+body, {'headers': this.headers, responseType: 'text' as 'json' });
       }
       save_myUser(req: string, body: any) {
        return this.http.post(this.serviceurl + req, body, {'headers': this.headers, responseType: 'text' as 'json' });
        }

        getcontribution_history(body:any, reqOpts?: any){
          return this.http.get(this.serviceurl+body, {'headers': this.headers });
         }
		   changePassword(url:any, body: any, reqOpts?: any) {

          return this.http.post(this.serviceurl + url, body, {'headers': this.headers, responseType: 'text' as 'json' });
        }

sendreceipt(endpoint, body: any, reqOpts?: any) {
    return this.http.post(this.serviceurl+endpoint,  body, {'headers': this.headers});
  }
  
  search_duplicateemail(body: any, reqOpts?: any) {
    return this.http.get(this.serviceurl + body, {'headers': this.headers });
  }
}
