import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ApiServiceService } from './provider/api-service.service';
import { AuthenticationService } from './provider/authentication-service';
import { SocialLoginModule, GoogleLoginProvider, FacebookLoginProvider, SocialAuthServiceConfig } from 'angularx-social-login';
import { User } from './provider/user';
import { AuthGuard } from './provider/guard/auth.guard';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { APP_BASE_HREF, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { IonicModule  } from '@ionic/angular';
import { IonicStorageModule, Storage } from '@ionic/storage';
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SocialLoginModule,
    NgxDaterangepickerMd,
	 IonicModule.forRoot(),
	  IonicStorageModule.forRoot()
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('416940806051-k3r7e664m5s2mch5706uf9o7a6rpjdmj.apps.googleusercontent.com')
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('806286969923337')
          }
        ]
      } as SocialAuthServiceConfig,
    },
    ApiServiceService,
    AuthGuard,
    PathLocationStrategy,
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    {provide: APP_BASE_HREF, useValue: '/'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
