import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '../authentication-service';
import { Observable } from 'rxjs';


@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

    user;

    constructor(private router: Router, private Authenticate: AuthenticationService) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        this.user = this.Authenticate.loginuser;
        // console.log(".............logged user in the AUTHGAURD page", this.user)
         if (this.user === "candidate" || this.user === "non-profit" || this.user === "others") {
            return true;
        }
        return false;
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        this.user = this.Authenticate.loginuser;
        // console.log(".............logged user in the AUTHGAURD page", this.user)
        if (this.user === "user") {
            return true;
        }
        return false;
    }
}
