// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: 'https://dev.changetomorrow.org/',
  apiURL : 'https://dev.changetomorrow.org/changetomorrow/',
  googlesitekey:'6LcZbkgaAAAAACuOzxK2E4mbaW7bnS15nT9BLfzD'
  //apiURL : 'https://changetomorrow.org/changetomorrow/'
};
export const dev_userpool_keys = {
  accessKeyId: 'AKIAQECKQ5KR2OSHP3FI',
  secretAccessKey: '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',
  region: 'us-east-1',
  Bucket: 'changetomorrow',
  imagePrefix: 'https://changetomorrow.s3.amazonaws.com/',
  encrypt_text:'cHangeTmrW21'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
